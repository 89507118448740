import { Head } from 'components/molecules';
import { signIn } from 'next-auth/react';
import { useEffect } from 'react';

export default function ()
{
  useEffect(() => { signIn('azure-ad-b2c', { callbackUrl: '/' }); }, []);
  return (
    <Head>
      <meta name="robots" content="noindex" />
    </Head>
  );
}